
@import 'tools';

$g-page-y-padding: rem(10);

/*
 FONTS
------------------------------------- */
html,
body {
    font-size: 12pt;
}

html,
body,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6,
div,
blockquote,
dt,
dd {
    font-family: Arial, sans-serif !important;
}


/*
 HIDE
------------------------------------- */

.page-footer,
.news-updates-search-filter,
.search-filter:not(.search),
.search-filter .help,
.search-filter .Actions,
.search-filter fieldset,
.block-news__button-wrap,
.block-building-code__button,
.icon-pdf,
.nav-in-this-document,
.codewords-issue .issue-summary .actions,
.codewords-issue .issue-summary h2,
.fancybox-overlay,
.filter-az.field,
.h1-wrap .code-button,
.h1-wrap::before,
.gallery-modal,
.gallery-modal .show-image-details,
.nav-pagination02,
.nav-breadcrumb,
.mobile-search,
#mobile-menu-container,
.mobile-menu,
.page-options,
.nav-main,
.nav-footer,
.nav-secondary,
.site-search-container,
.page-header .home-logo,
.nav-main-link-back,
.nav-tertiary,
.nav-stepped-anchors,
.link-back-to-top,
.nav-pagination,
.block-signup,
.nav-list,
.nav-issue,
.expand-item,
.js-trigger,
.show-more,
.mbie-modal,
[class^='icon-']::before,
[class*=' icon-']::before,
.content-wrapper ul li::before,
.nav-footer.last .copyright,
.js-table-wrapper .view-full-table,
.js-table-wrapper .js-scroll-trigger,
.js-cell-responsive,
.block-notification,
.rating {
    display: none !important;
}


/*
 SHOW
------------------------------------- */

.print,
.js-reveal {
    display: block !important;
}

.js-table-wrapper tr.js-hidden {
    display: table-row !important;
}

.js-table-wrapper td.js-hidden {
    display: table-cell !important;
}


/*
 BACKGROUND
------------------------------------- */

.page-header {
    background: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}


/*
 SPECIFIC PRINT STYLES
------------------------------------- */

body {
    background: #fff;
    color: #000;
}

#shell {
    padding: rem(20) $g-page-y-padding rem(5);
}

.page-maincontent .page-wrapper {
    padding: 0;
}

.layout-col.main-right {
    float: none;
    width: auto;
}

.content-wrapper,
.page-wrapper {
    max-width: none;
}

.getting-started-landing-intro .layout-col-3 {
    display: none;
}

.getting-started-landing-intro .layout-col-9 {
    float: none;
    width: 100%;
    margin-left: 0;
}

.content-wrapper ul {
    list-style: disc;
}

.pin-wrapper {
    height: auto !important;
}

.overflow-liner {
    overflow: visible;
    overflow-y: visible;
}

.guidance-overview .block-document-intro .clear {
    margin-top: 1em;
}

.block-document-intro .image {
    position: static;
    float: left;
}

.block-document-intro .block-document-meta {
    clear: both;
    margin: 2em 0 0 !important;
}

#content .building-checklist,
#content .building-checklist ul {
    list-style: none;
}

#content .building-checklist li::before {
    display: block !important;
    border-color: $mbiebuilding_gray_3;
}

.building-checklist-note,
.building-highlight-note-blue,
.building-highlight-note-white,
.building-highlight-note-gray {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px !important;
    border-left: 5px solid $mbiebuilding_gray_3;
    margin: 1.5em 0 !important;
    color: #666;
}


/*
 HEADER
------------------------------------- */

.page-header img.print {
    width: rem(150);
    height: auto;
    margin: 0 0 rem(10);
}

/*
 MAIN CONTENT
------------------------------------- */

/* Links */
#content a::after,
.content-wrapper a::after {
    position: static !important;
    top: inherit !important;
    display: inline-block;
    content: ' (https://www.building.govt.nz' attr(href) ')';

    // reset for external links
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    text-align: inherit !important;
    text-decoration: underline;
    word-wrap: break-word;
}

// Anchor links
a[name] {
    display: none !important;
}

// External links

a[href^='tel:']::after,
a[href^='https://']::after,
a[href^='http://']::after {
    content: ' (' attr(href) ')' !important;
}

// Internal link where the href does not start with /
a:not([href^='/'])::after {
    content: ' (https://www.building.govt.nz/' attr(href) ')' !important;
}

// Internal link to leave as is
a[href^='http:']::after,
a[href^='https:']::after,
a[href^='tel:']::after {
    content: attr(href) !important;
}

// Internal link where the href starts with /
a[href^='/']::after {
    content: ' (https://www.building.govt.nz' attr(href) ')' !important;
}

// Remove styles added for external link icon
a:not(.button) {
    &[href^='http://']:not([href*='building.govt.nz']),
    &[href^='https://']:not([href*='building.govt.nz']) {
        &::after {
            margin: 0;
            font-family: Arial, sans-serif;
            font-size: 12pt;
        }
    }
}

a::after {
    font-family: Arial, sans-serif !important;
    color: currentColor !important;
}

.block-code-nav-bar .code-button::after {
    display: none;
}

/* Color */

p,
li,
label {
    color: #000 !important;
}

/* Typographic */

.h1-wrap,
.h2-wrap {
    border-bottom: 4px solid $mbiebuilding_body;
    margin-top: 30px;
    margin-bottom: 2em;
}

/* Images */

.figure.align-left img,
.figure.align-right img,
.captionImage.left img,
.captionImage.right img {
    width: auto;
    max-width: 100%;
}

/* Tables */

.page-maincontent table {
    width: 95%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    border-spacing: 0;
    margin-right: 1%;
    margin-left: 1%;
}

.page-maincontent th,
.page-maincontent td {
    vertical-align: top;
    padding: .5em;
    border: none;
    border-right: 1px solid #ccc;
    text-align: left;
}

.page-maincontent th {
    border-bottom: 2px solid #ccc;
    color: #000;
}

.page-maincontent td {
    white-space: normal !important;
}

.page-maincontent tr,
.page-maincontent tr th,
.page-maincontent tr td,
.page-maincontent img {
    page-break-inside: avoid;
}

.page-maincontent thead {
    display: table-header-group;
}

.page-maincontent th p,
.page-maincontent td p,
.page-maincontent th li,
.page-maincontent td li {
    background-color: #fff !important;
    color: #000 !important;
}

.page-maincontent th ul,
.page-maincontent td ul {
    padding: 0 inherit !important;
    margin: 0 0 1em 1em !important;
}

.page-maincontent th p,
.page-maincontent td p {
    padding: 0 !important;
    margin: 0 0 1em !important;
}

/* Misc */

/* .js-pagemetacontainer { overflow:hidden; margin:0 0 1em; }
.js-pagemetacontainer.bottom { margin:2em 0 0; }
.js-pagetitle, .js-pageurl { font-size:10px; margin:0; text-align:left; float:left; width:40%; }
.js-pageurl { text-align:right; width:60%; }
*/

/* Overflow fixes for Firefox */
@supports (-moz-appearance: none) {
    .layout-row,
    .page-maincontent dl {
        clear: both;
        overflow: visible !important;
    }

    .layout-row::before,
    .layout-row::after,
    .page-maincontent dl::before,
    .page-maincontent dl::after {
        display: block;
        content: '';
        clear: both;
        width: 100%;
        height: 1px;
    }
}

/*
 Select2
------------------------------------- */

.select2-container--default .select2-selection--single {
    border: 0;
    background: transparent;
}

.select2-selection__arrow {
    display: none;
}

/*
 SPECIFIC PAGE BREAKS
------------------------------------- */

.page-break {
    display: block;
    page-break-before: always;
}


/*
 GALLERY MODAL
------------------------------------- */

.gallery-modal,
.gallery-modal .image-details {
    background: #fff;
    color: $mbiebuilding_body;
}

#shell .gallery-modal .page-options {
    display: none !important;
}

/* Hide background during printing */

.print-gallery-modal .page-header,
.print-gallery-modal .page-footer,
.print-gallery-modal .page-maincontent {
    display: none !important;
}

.print-gallery-modal .fancybox-overlay,
.print-gallery-modal .gallery-modal,
.print-gallery-modal .gallery-modal .page-maincontent {
    display: block !important;
}

/* Other modal related */

.fancybox-lock,
.fancybox-lock body {
    overflow: visible !important;
}

.close-modal {
    display: none !important;
}

/*
 FOOTER
------------------------------------- */

.site-disclaimer {
    padding: rem(20) $g-page-y-padding rem(20);
    border-top: 1px solid $mbiebuilding_body;
    margin: rem(30) 0 0;
    background: #fff;
    font-size: 12px;
    line-height: 16px;
    color: #505050 !important;
}

.site-disclaimer li,
.site-disclaimer p {
    font-size: inherit !important;
    line-height: inherit !important;
}

.site-disclaimer ul {
    margin: .5em 2em;
    list-style: disc;
}

.page-articlepage .site-disclaimer,
.page-guidancepage .site-disclaimer,
.page-guidancereadingpage .site-disclaimer,
.page-codewordslandingpage .site-disclaimer,
.page-codewordspage .site-disclaimer {
    display: block !important;
}

/*
 Campaign page
------------------------------------- */
.campaign-page .section-wrapper {
    &:first-child {
        border-top: 0;
    }
}

/*
 Search page
------------------------------------- */
.search-results-with-sort .search-results-header {
    padding-top: 0;
}

.search-filter .header {
    border: 0;
}
